<template>
  <div class="store-container">
    <div class="store-content">
      <div
        class="store-banner"
        style="display:flex; justify-content: center;padding: 2% 16%"
      >
        <div
          id="banner_slides"
          class="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="2000"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="@/assets/Store_banner.jpeg"
                class="d-block w-100"
                alt="@/assets/Store_banner.jpeg"
              />
            </div>
            <div class="carousel-item">
              <img
                src="@/assets/Store_banner.jpeg"
                class="d-block w-100"
                alt="@/assets/Store_banner.jpeg"
              />
            </div>
            <div class="carousel-item">
              <img
                src="@/assets/Store_banner.jpeg"
                class="d-block w-100"
                alt="@/assets/Store_banner.jpeg"
              />
            </div>
          </div>
          <button
            style="border: none;width: 5%"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#banner_slides"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon"></span>
          </button>
          <button
            style="border: none;width: 5%"
            class="carousel-control-next"
            type="button"
            data-bs-target="#banner_slides"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon"></span>
          </button>
        </div>
      </div>
      <div
        v-if="cards.length"
        class="row row-cols-1 row-cols-sm-2 row-cols-md-4"
        style="padding: 2% 10%;"
      >
        <div class="col p-2" v-for="(card, index) in cards" :key="index">
          <StoreCard :card_data="card" @buy_bundle="buy_handler" />
        </div>
      </div>

      <div
        v-else
        class="row row-cols-1 row-cols-sm-2 row-cols-md-4"
        style="padding: 2% 10%;"
      >
        <img style="margin: auto" :src="loading_img_url" />
      </div>

      <div class="row" style="padding: 2% 10%;">
        <div class="store-pagination">
          <img
            v-if="active_page == 1"
            @click="active_page > 1 ? (active_page = active_page - 1) : null"
            :class="active_page == 1 ? '' : 'button-overlay'"
            src="@/assets/Button_Next_disabled.png"
            :style="active_page == 1 ? '' : 'transform: rotateZ(180deg)'"
          />
          <img
            v-else
            @click="active_page > 1 ? (active_page = active_page - 1) : null"
            :class="active_page == 1 ? '' : 'button-overlay'"
            src="@/assets/Button_Next.png"
            :style="active_page == 1 ? '' : 'transform: rotateZ(180deg)'"
          />
          <div v-for="page in total_pages" :key="page.key">
            <div
              @click="active_page = page"
              class="button-overlay"
              :style="page == active_page ? 'color: #EE4A31' : ''"
            >
              {{ page }}
            </div>
          </div>
          <img
            v-if="active_page == total_pages"
            @click="
              active_page < total_pages ? (active_page = active_page + 1) : null
            "
            :class="active_page == total_pages ? '' : 'button-overlay'"
            src="@/assets/Button_Next_disabled.png"
            :style="
              active_page == total_pages ? 'transform: rotateZ(180deg)' : ''
            "
          />
          <img
            v-else
            @click="
              active_page < total_pages ? (active_page = active_page + 1) : null
            "
            :class="active_page == total_pages ? '' : 'button-overlay'"
            src="@/assets/Button_Next.png"
            :style="
              active_page == total_pages ? 'transform: rotateZ(180deg)' : ''
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreService from "../resources/store_service";
import StoreCard from "../components/store_card";

export default {
  name: "store",
  components: {
    StoreCard
  },
  data() {
    return {
      loading_img_url: 'https://cdn.discordapp.com/attachments/780994260471775272/983601360404615178/S__41820187.gif',
      cards: [],
      active_page: 1,
      total_bundles: 0,
      page_limit: 16, //Adjustable
      total_pages: 1,
      StoreService: new StoreService()
    };
  },
  methods: {
    index_bundles() {
      this.StoreService.index_bundles(this.active_page, this.page_limit).then(
        res => {
          this.total_pages = res.lastPage;
          this.total_bundles = res.total;
          this.cards = res.data;
        }
      );
    },
    buy_handler(val) {
      this.$router.push(`/voucher/${val.id}`);
    }
  },
  watch: {
    active_page: function(oldValue, newValue) {
      if (newValue) {
        this.cards = []
        this.index_bundles();
      }
    }
  },
  mounted() {
    this.index_bundles();
  }
};
</script>

<style scoped>
.store-content {
  padding: 1vw 5;
  overflow: hidden;
}
.store-banner {
  position: relative;
}
.store-banner-text {
  position: absolute;
  top: 40%;
  left: 32%;
  font-weight: 300;
  font-size: 21px;
  color: white;
}
.store-banner > img {
  width: 66%;
  margin: 1.5vw 0;
}
.store-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.store-pagination {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  margin: auto;
}
.store-pagination > img {
  height: 50px;
}

.button-overlay:hover {
  user-select: none;
  cursor: pointer;
  filter: brightness(70%);
}
.store-column {
  flex: 25%;
}
.store-column .card-wrapper {
  vertical-align: middle;
  margin: 1.5%;
}
@media (max-width: 1200px) {
  .store-column {
    flex: 30%;
  }
}
@media (max-width: 700px) {
  .store-column {
    flex: 100%;
  }
}
@media (max-width: 400px) {
  .store-column {
    flex: 100%;
  }
}
</style>

<template>
  <div class="card-container" @click="buy_bundle()">
    <div class="card-preview">
      <img
        v-if="card_data.image"
        class="card-preview-image"
        :src="card_data.image"
      />
      <img v-else class="card-preview-image" src="@/assets/Matchday_Logo.jpg" />
      <img
        v-if="card_data.price_original > 0"
        class="card-preview-tag"
        src="@/assets/Tag_discount.png"
      />
      <img
        v-if="card_data.provider.logo"
        class="card-preview-logo"
        :src="card_data.provider.logo"
      />
      <div
        v-if="card_data.price_original > 0"
        class="card-preview-tag tag-value"
      >
        -{{
          (
            ((card_data.price_original - card_data.price) /
              card_data.price_original) *
            100
          ).toFixed(0)
        }}%
      </div>
      <div v-if="card_data.provider_sport" class="card-preview-sport">
        <img
          style="height: 100%; width: auto;"
          :src="card_data.provider_sport.sport.icon"
        />
        <div class="card-preview-sport-text">
          {{ card_data.provider_sport.sport.name }}
        </div>
      </div>
    </div>
    <div class="card-info">
      <div class="card-info-section card-info-center">
        <div class="card-info-name">{{ card_data.name }}</div>
        <div class="card-info-detail card-info-provider">
          {{ card_data.provider.fullname }}
        </div>
      </div>
      <div id="card-serving-dow" class="card-info-section">
        <div class="card-info-center">
          ใช้ได้ในช่วงเวลา
          {{
            card_data.serving_period
              ? card_data.serving_period
              : `${card_data.provider.minTime} - ${card_data.provider.maxTime}`
          }}
        </div>
        <div class="card-info-center">
          <span class="fa-layers fa-fw">
            <i
              class="fas fa-circle"
              :style="[
                card_data.serving_dow.includes('0')
                  ? { color: 'red' }
                  : { color: '#d3d3d3' }
              ]"
            ></i>
            <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-6"
              >M</span
            >
          </span>
          <span class="fa-layers fa-fw">
            <i
              class="fas fa-circle"
              :style="[
                card_data.serving_dow.includes('1')
                  ? { color: 'red' }
                  : { color: '#d3d3d3' }
              ]"
            ></i>
            <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-6"
              >T</span
            >
          </span>
          <span class="fa-layers fa-fw">
            <i
              class="fas fa-circle"
              :style="[
                card_data.serving_dow.includes('2')
                  ? { color: 'red' }
                  : { color: '#d3d3d3' }
              ]"
            ></i>
            <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-6"
              >W</span
            >
          </span>
          <span class="fa-layers fa-fw">
            <i
              class="fas fa-circle"
              :style="[
                card_data.serving_dow.includes('3')
                  ? { color: 'red' }
                  : { color: '#d3d3d3' }
              ]"
            ></i>
            <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-6"
              >Th</span
            >
          </span>
          <span class="fa-layers fa-fw">
            <i
              class="fas fa-circle"
              :style="[
                card_data.serving_dow.includes('4')
                  ? { color: 'red' }
                  : { color: '#d3d3d3' }
              ]"
            ></i>
            <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-6"
              >F</span
            >
          </span>
          <span class="fa-layers fa-fw">
            <i
              class="fas fa-circle"
              :style="[
                card_data.serving_dow.includes('5')
                  ? { color: 'red' }
                  : { color: '#d3d3d3' }
              ]"
            ></i>
            <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-6"
              >Sa</span
            >
          </span>
          <span class="fa-layers fa-fw">
            <i
              class="fas fa-circle"
              :style="[
                card_data.serving_dow.includes('6')
                  ? { color: 'red' }
                  : { color: '#d3d3d3' }
              ]"
            ></i>
            <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-6"
              >S</span
            >
          </span>
        </div>
      </div>
      <div class="card-price">
        <div class="card-price-info">
          <div v-if="card_data.price_original > 0" class="card-price-sub">
            {{ card_data.price_original }} /ชม.
          </div>
          <div class="card-price-pri">{{ card_data.price }} ฿</div>
        </div>
        <div class=""></div>
        <img
          class="button-overlay"
          style="width: 35%;"
          src="@/assets/Store_buy_button.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreCard",
  props: {
    card_data: Object
  },
  methods: {
    buy_bundle() {
      const data = {
        id: this.card_data.id,
        name: this.card_data.name,
        provider: this.card_data.provider.fullname,
        price: this.card_data.price,
        image: this.card_data.image,
        permanent: this.card_data.permanent,
        expire_start: this.card_data.expire_start,
        expire_end: this.card_data.expire_end
      };
      this.$emit("buy_bundle", data);
    },
    addedPrice(price) {
      return price + (price * 20) / 100;
    }
  }
};
</script>

<style scoped>
.card-container {
  width: 100%;
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 25%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}
/* .card-container:hover {
  cursor: pointer;
  transform: scale(1.10);
  height: 100%;
} */
.card-preview {
  position: relative;
}
.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: 3%;
}
.card-info-name {
  font-size: 1.2rem;
  font-weight: 500;
}
.card-info-provider {
  line-height: 1;
}
.card-info-center {
  text-align: center;
}
.card-info-section {
  margin-top: 5px;
  margin-bottom: 5px;
}
.card-preview-image {
  width: 100%;
  height: 157px;
  object-fit: cover;
  border-radius: 12px 12px 0px 0px;
}
/* .card-preview-image:hover {
  height: 100%
} */
.card-preview-logo {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  object-fit: cover;
}
.card-preview-tag {
  position: absolute;
  top: -5px;
  right: 9px;
  height: 65px;
  backdrop-filter: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0) 0%,
    rgba(255, 0, 0, 0.65) 100%
  );
}
.tag-value {
  font-size: 14px;
  font-weight: 600;
  top: 12px;
  right: 16px;
  color: white;
}
.card-preview-sport {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 35%;
  height: 14%;
  padding: 4px;
  background-color: #e3f3ff;
  border-radius: 20px;
  border: 1px solid #1379e6;
}
.card-price {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  line-height: 110%;
}
.card-price-pri {
  display: flex;
  font-size: 150%;
  color: #ee4a31;
}
.card-price-sub {
  font-size: 90%;
  font-weight: 300;
  color: #a7abbe;
  text-decoration: line-through;
}
.button-overlay:hover {
  user-select: none;
  cursor: pointer;
  filter: brightness(70%);
}
</style>
